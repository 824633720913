import React, { FC } from "react";

import { KModal, KModalContentSection, KModalHeader } from "@keepeek/refront-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";

import useFrontEditModal from "../../providers/frontEdit/hooks/useFrontEditModal";
import FrontEditEditionForm from "./FrontEditEditionForm";

const EditionModal: FC<React.PropsWithChildren<unknown>> = function () {
  const { isOpen, setIsOpen, currentEditKeys } = useFrontEditModal();

  const { t } = useTranslation();
  if (!isOpen) {
    return null;
  }
  return (
    <KModal
      disableEnforceFocus={true}
      scrollbarVisible={true}
      header={
        <KModalHeader
          onClose={() => {
            setIsOpen(false);
          }}
          title={t("front-edit.edition-modal.element-edition.label") as string}
        />
      }
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
    >
      <KModalContentSection>
        <DndProvider backend={HTML5Backend}>
          <FrontEditEditionForm keys={currentEditKeys} />
        </DndProvider>
      </KModalContentSection>
    </KModal>
  );
};

export default EditionModal;
